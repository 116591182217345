import React from 'react'
import BlogForm from "../components/blog-form"
// import BlogBannerBG from "../images/blog-header.svg"


const CustomBlogBanner = (props) => {
  const imgBanner = require('../images/blog-header.svg')

  return (
    <div className="section-custom-blog-banner">
      <div className="container" style={{ backgroundImage: `url(${imgBanner})` }}>
        <h1 className="heading-1">The Billsby Blog</h1>
        <p className="subheading-1">Thoughts on Software-as-a-Service, the subscription economy and recurring billing - and the latest updates on Billsby.</p>
        <BlogForm
          className="form-left"
        />
      </div>
    </div>
  )
}

export default CustomBlogBanner