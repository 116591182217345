import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
// import { Helmet } from 'react-helmet'
import SEO from "../components/seo"
import CustomBlogBanner from "../components/blog-banner"
import BlogCard from "../components/blog-card"
import BlogPageDetails from "../components/blog-page-details"
import Moment from "react-moment"

const CategoryPage = ({ data }) => {
  const [visiblePosts, setVisiblePosts] = useState(30)

  //eslint-disable-next-line
  const [numberOfPosts, setNumberOfPosts] = useState(data.gcms.blogCategory.blogPost.length)

  const loadMore = () => {
    setVisiblePosts(prevState => prevState + 30)
  }

  const blogPostsArray = data.gcms.blogCategory.blogPost

  blogPostsArray.sort((a, b) => {
    var c = new Date(a.publicationDate)
    var d = new Date(b.publicationDate)

    return d - c
  })

  return (
    <Layout>
      <SEO
        title={data.gcms.blogCategory.categoryName + " | The Billsby Blog"}
        description={
          "Read the latest posts about" +
          data.gcms.blogCategory.categoryName +
          "on The Billsby Blog for the latest insights on subscription billing and recurring payments."
        }
        url={"https://www.billsby.com/category/" + data.gcms.blogCategory.slug}
      />

      <CustomBlogBanner />

      <BlogPageDetails
        blogPageType="blogCategory"
        category={data.gcms.blogCategory.categoryName}
      />

      <div className="section-blog-list">
        <div className="container">
          <div className="list-wrapper">
            {
              blogPostsArray.slice(0, visiblePosts).map(post => {
                // const date = new Date(post.publicationDate)

                // const appendLeadingZeroes = (n) => {
                //   if (n <= 9) {
                //     return "0" + n;
                //   }
                //   return n
                // }

                // const formattedDate = date.getFullYear() + "/" + appendLeadingZeroes(date.getMonth() + 1) + "/" + appendLeadingZeroes(date.getDate())

                var url = post.slug
                var cat = post.blogCategory !== null ? post.blogCategory.categoryName : null
                var catSlug = post.blogCategory !== null ? post.blogCategory.slug : null

                return (
                  <BlogCard
                    // featuredImage={post.featuredImage.url}
                    key={post.id}
                    image={post.image}
                    category={cat}
                    categorySlug={catSlug}
                    title={post.title}
                    url={url}
                    date={<Moment format="MMMM DD YYYY">
                      {post.publicationDate}
                    </Moment>
                  }
                />
              )
            })}
          </div>
          <div className="btn-wrapper">
            {visiblePosts < numberOfPosts && (
              <button className="btn-blue" onClick={loadMore}>
                Load more post
              </button>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CategoryPage

export const query = graphql`
  query CategoryTemplate($categoryId: ID!) {
    gcms {
      blogCategory(where: { id: $categoryId }) {
        categoryName
        slug
        blogPost {
          id
          title
          slug
          blogCategory {
            slug
            categoryName
          }
          image: featuredImage {
            handle
            width
            height
          }
          publicationDate
        }
      }
    }
  }
`
